import qs from "qs"
import { FilterTypesInstantSearch } from "~/utils/enums/FilterTypesInstantSearch"
import { FilterTypesContentService } from "~/utils/enums/FilterTypesContentService"

const indexName = "offers"

function activeFiltersKeys(activeFilters: any) {
  return activeFilters.map(obj => obj.key).flat()
}

/**
 * Setup behaviour of instantsearch routing.
 * https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/vue/?client=Vue+3#combining-with-nuxtjs
 * @param vueRouter
 * @param activeFilters
 */
export function useNuxtInstantsearchRouter(vueRouter, activeFilters) {
  return {
    /* Reads from the URL and returns a routeState */
    read() {
      return createRouteStateFromRoute(vueRouter.currentRoute, activeFilters)
    },
    /* Writes to the URL */
    write(routeState) {
      // Only push a new entry if the URL changed (avoid duplicated entries in the history)
      if (this.createURL(routeState) === this.createURL(this.read()))
        return

      const route = createRouteFromRouteState(routeState, activeFilters)
      vueRouter.push(route)
    },
    /* Returns a URL as a string */
    createURL(routeState) {
      return createURLFromRouteState(routeState, activeFilters)
    },
    /* Calls this callback whenever the URL changes externally */
    onUpdate(cb) {
      if (typeof window === "undefined")
        return

      this._removeAfterEach = vueRouter.afterEach(() => {
        cb(this.read())
      })

      this._onPopState = () => {
        cb(this.read())
      }
      window.addEventListener("popstate", this._onPopState)
    },
    /* Removes any listeners */
    dispose() {
      if (typeof window === "undefined")
        return

      if (this._onPopState)
        window.removeEventListener("popstate", this._onPopState)

      if (this._removeAfterEach)
        this._removeAfterEach()
    },
  }
}

/**
 * Creates a route object from a typesense state object to be pushed in to Vue router (for history)
 * @param routeState
 * @param activeFilters
 */
function createRouteFromRouteState(routeState, activeFilters) {
  const route = {
    path: "search",
    query: { // = url params
      query: routeState[indexName]?.query, // free text query search
    },
  }

  // keep potential other "foreign" query params
  const queriesFromUrl = qs.parse(window.location.search.slice(1))
  const foreignQueries = Object.fromEntries(
    Object.entries(queriesFromUrl).filter(
      ([key]) =>
        !activeFiltersKeys(activeFilters).includes(key) && !(key === "query"),
    ),
  )
  Object.assign(route.query, foreignQueries)

  activeFilters.forEach((filter) => {
    let filterObjectKey = FilterTypesInstantSearch.REFINEMENT_LIST
    if (filter.type === FilterTypesContentService.SLIDER)
      filterObjectKey = FilterTypesInstantSearch.RANGE

    Object.assign(route.query, { [filter.key]: routeState[indexName]?.[filterObjectKey]?.[filter.key] })
  })

  return route
}

/**
 * Creates a URL query string from an instantsearch route state
 * @param routeState
 * @param activeFilters
 */
function createURLFromRouteState(routeState, activeFilters) {
  if (routeState) {
    const params = {
      query: routeState[indexName]?.query, // free text query search
    }

    activeFilters.forEach((filter) => {
      let filterObjectKey = FilterTypesInstantSearch.REFINEMENT_LIST
      if (filter.type === FilterTypesContentService.SLIDER)
        filterObjectKey = FilterTypesInstantSearch.RANGE

      Object.assign(params, { [filter.key]: routeState[indexName]?.[filterObjectKey]?.[filter.key] })
    })

    // keep potential other "foreign" query params
    const queriesFromUrl = qs.parse(window.location.search.slice(1))
    const foreignQueries = Object.fromEntries(
      Object.entries(queriesFromUrl).filter(
        ([key]) =>
          !activeFiltersKeys(activeFilters).includes(key) && !(key === "query"),
      ),
    )

    return qs.stringify({ ...params, ...foreignQueries }, {
      addQueryPrefix: true,
      arrayFormat: "repeat",
      format: "RFC3986",
    })
  }
}

/**
 * Creates a state object for instantsearch from the vue route
 * @param route
 * @param activeFilters
 */
function createRouteStateFromRoute(route, activeFilters) {
  const routeState = {
    [indexName]: {
      query: route.value?.query.query, // free text query search
      refinementList: {},
      range: {},
    },
  }
  activeFilters.forEach((filter) => {
    if (filter.type === FilterTypesContentService.SLIDER) {
      Object.assign(
        routeState[indexName].range,
        { [filter.key]: route.value?.query[filter.key] },
      )
    }
    else {
      Object.assign(
        routeState[indexName].refinementList,
        { // always make it an array, even when only 1 entry, or empty
          [filter.key]: route.value?.query[filter.key] ? [].concat(route.value?.query[filter.key]) : [],
        },
      )
    }
  })

  return routeState
}
