<template>
  <div>
    <InstantSearchContainer />
  </div>
</template>

<script setup lang="ts">
import { useFeatureFlag } from "~/composables/useFeatureFlag"

// Redirect to index page if feature flag is not enabled
const isSearchAndFiltersEnabled = await useFeatureFlag("isSearchAndFiltersEnabled")
if (!isSearchAndFiltersEnabled)
  await navigateTo({ name: "index" }, { replace: true })

// Track page
usePageTracking()

// For now hard code search page title
useServerSeoMeta({
  title: () => "Search",
  ogTitle: () => "Search",
})

// set canonical tag as baseUrl + search
const { data: jobShop } = useNuxtData("jobShopData")
useHead(() => ({
  link: [
    {
      rel: "canonical",
      href: `${jobShop.value?.jobShopUrl}search`,
    },
  ],
}))
</script>
