<template>
  <ClientOnly>
    <template #fallback>
      <CRSearchLoadingPlaceholder :job-search-global="jobSearchGlobal" />
    </template>

    <AisInstantSearch
      :search-client="searchClient"
      :routing="routing"
      :index-name="indexName"
      :insights="{
        insightsClient: null,
        onEvent: (event: any) => {
          handleTrackInsightsEvent(event);
        },

      }"
    >
      <CRSearchFilterPageTemplate :computed-function="computed" :job-search-global="jobSearchGlobal" @filter-clicked="handleTrackFilterOpened($event)">
        <template #search-box>
          <AisSearchBox
            autofocus
            :placeholder="jobSearchGlobal.config.search.placeholderText"
            @input="handleTrackSearchInput"
          />
        </template>

        <template #filter-list="{ filter }">
          <CRListFilterWrapper :filter-key="filter.key">
            <AisRefinementList :attribute="filter.key" searchable :limit="1000" :sort-by="listSorting">
              <template
                #default="{
                  items,
                  isFromSearch,
                  refine,
                  createURL,
                  searchForItems,
                  sendEvent,
                }"
              >
                <CREndlessFilterList
                  :attribute="filter.key"
                  :items="items"
                  :refine="refine"
                  :search-for-items="searchForItems"
                  :create-u-r-l="createURL"
                  :is-from-search="isFromSearch"
                  :send-event="sendEvent"
                  :job-search-global="jobSearchGlobal"
                />
              </template>
            </AisRefinementList>
          </CRListFilterWrapper>
        </template>

        <template #filter-select="{ filter }">
          <AisRefinementList
            :attribute="filter.key"
            :limit="1000"
            :class-names="{
              'ais-RefinementList': 'search-filter-filter-select-list',
            }"
            :sort-by="listSorting"
          >
            <template #default="{ items, refine }">
              <button
                v-for="item in items.sort((a, b) => a.value < b.value)"
                :key="item.value"
                class="select-button"
                :class="item.isRefined ? '-active' : ''"
                @click.prevent="refine(item.value)"
              >
                {{ item.value }}
              </button>
            </template>
          </AisRefinementList>
        </template>

        <template #filter-slider="{ filter }">
          <AisRangeInput :attribute="filter.key">
            <template #default="{ currentRefinement, range, refine }">
              <div :ref="`slider-${filter.key}`" class="slider-container">
                <Slider
                  :min="range.min"
                  :max="range.max"
                  :model-value="toValue(currentRefinement, range)"
                  :tooltips="true"
                  tooltip-position="bottom"
                  @update="refineRange(refine, $event)"
                  @slide="
                    setSliderTooltipOrientation($event, range, filter.key)
                  "
                />
              </div>
            </template>
          </AisRangeInput>
        </template>

        <template #single-filter-reset="{ filter }">
          <AisClearRefinements :included-attributes="[filter.key]">
            <template #resetLabel>
              {{ jobSearchGlobal.config.filters.resetButtonsText.singleFilter }}
            </template>
          </AisClearRefinements>
        </template>

        <template #stats>
          <AisStats>
            <template #default="{ nbHits }">
              <CRSearchStats ref="searchStats" :number-of-hits="nbHits" :stats-text="jobSearchGlobal.config.results.amountHitsText" :total-count="totalOffersCount" />
            </template>
          </AisStats>
        </template>

        <template #sorting-select>
          <AisSortBy :items="jobSearchGlobal.config.sorting.items" />
        </template>

        <template #all-filter-reset>
          <AisClearRefinements>
            <template #default="{ canRefine, refine }">
              <CRResetFiltersButton
                :label="
                  jobSearchGlobal.config.filters.resetButtonsText.allFilters
                "
                :disabled="!canRefine"
                @click.prevent="refine"
                @mousedown="handleTrackClickRemoveAllFilter"
              />
            </template>
          </AisClearRefinements>
        </template>

        <template #results>
          <AisInfiniteHits>
            <template #item="{ item, index }">
              <IntersectWrapper @intersects="handleTrackViewSearchResult(item, index)">
                <CRResultItem :item="item" :job-search-global="jobSearchGlobal" :data-id="item.id" />
              </IntersectWrapper>
            </template>

            <template #loadMore="{ isLastPage, refineNext }">
              <CRInfiniteScrollTrigger
                :is-last-page="isLastPage"
                @trigger-intersected="refineNext"
              />
            </template>
          </AisInfiniteHits>
        </template>

        <template #empty-state>
          <AisStateResults>
            <template #default="{ results: { hits } }">
              <CRResultsEmptyState
                v-show="hits.length === 0"
                :headline="jobSearchGlobal.config.results.emptyState.headline"
                :subline="jobSearchGlobal.config.results.emptyState.subline"
              />
            </template>
          </AisStateResults>
        </template>
      </CRSearchFilterPageTemplate>
    </AisInstantSearch>
  </ClientOnly>
</template>

<script lang="ts">
import {
  AisClearRefinements,
  AisInfiniteHits,
  AisInstantSearch,
  AisRangeInput,
  AisRefinementList,
  AisSearchBox,
  AisSortBy,
  AisStateResults,
  AisStats,
} from "vue-instantsearch/vue3/es"
import Slider from "@vueform/slider"
import { searchAndFilterMethods } from "@jobshop/crossroads/components/SearchAndFilter/mixins/searchAndFilter"
import { debounce } from "radash"
import { useTypesenseSearchClient } from "~/composables/useTypesenseSearchClient"
import { InsightsEvents } from "~/utils/enums/InsightsEvents"

const indexName = "offers"
let activeFilters = []

export default defineNuxtComponent({
  components: {
    AisClearRefinements,
    AisInfiniteHits,
    AisInstantSearch,
    AisRangeInput,
    AisRefinementList,
    AisSearchBox,
    AisSortBy,
    AisStateResults,
    AisStats,
    Slider,
  },
  mixins: [searchAndFilterMethods],
  async setup() {
    // Provide search config
    const { data: jobShop } = useNuxtData("jobShopData")
    const jobSearchGlobal = ref(jobShop.value?.search)

    // Get typesense search client
    const { searchClient } = await useTypesenseSearchClient(jobSearchGlobal.value?.config)

    // read active filters for usage in routing
    activeFilters = jobSearchGlobal.value?.config.filters.options.filter((filter) => {
      return filter.active
    })

    // setup routing
    const router = useRouter()
    const routing = {
      router: useNuxtInstantsearchRouter(router, activeFilters),
    }

    // Track loaded filters
    // Only possible on client, since amplitude is a client-side plugin
    if (import.meta.client) {
      jobSearchGlobal.value?.config.filters.options.forEach((filter: FilterOption) => {
        if (filter.active)
          trackFilterLoaded(filter.key)
      })
    }

    // Use ref on CRSearchStats to track number of results
    const searchStats = ref(null)
    watchEffect(() => {
      if (searchStats.value)
        trackLoadSearchResults(searchStats.value.numberOfHits)
    })

    // Define sorting for list and select filters
    const listSorting = ref(["isRefined", "name:asc"])

    // Get total offers
    const totalOffersCount = ref(0)
    const fetchTotalOffersCount = async () => {
      const response = await searchClient.value.search([
        {
          indexName: "offers",
          params: {
            query: "",
          },
        },
      ])

      totalOffersCount.value = response.results[0].nbHits
    }

    return {
      routing,
      jobSearchGlobal,
      searchClient,
      searchStats,
      indexName,
      listSorting,
      totalOffersCount,
      fetchTotalOffersCount,
    }
  },
  mounted() {
    this.fetchTotalOffersCount()
  },
  methods: {
    handleTrackSearchInput: debounce({ delay: 1000 }, (event) => {
      const searchRequest = event.target.value

      if (searchRequest)
        trackSearchApplied(searchRequest)
    }),
    handleTrackFilterOpened(event: string) {
      trackFilterOpened(event)
    },
    handleTrackClickRemoveAllFilter() {
      trackClickRemoveAllFilter()
    },
    handleTrackViewSearchResult(item: any, itemIndex: number) {
      trackViewSearchResult(item.id, item.title, itemIndex + 1)
    },
    handleTrackInsightsEvent(event: any) {
      switch (event.insightsMethod) {
        case InsightsEvents.CLICKED_FILTERS:
          trackFilterSelected(event.attribute, event.payload.filters[0].split(":")[1])
          break
        case InsightsEvents.CLICKED_RESULTS:
          trackClickSearchResult(event.hits[0].id, event.hits[0].title, event.payload.positions[0])
          break
      }
    },
  },
})
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style src="@jobshop/crossroads/assets/scss/search-filter/_index.scss"></style>
