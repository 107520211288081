import { nanoid } from "nanoid"
import * as configcat from "configcat-js-ssr"

export async function useFeatureFlag(featureFlagKey: string) {
  const { $configCat } = useNuxtApp()
  const { tenantId } = useHeaderStore()

  return await $configCat.getValueAsync(
    featureFlagKey,
    false,
    new configcat.User(nanoid(), "", "", {
      tenantId,
    }),
  )
}
